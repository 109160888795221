$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements(){
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function(){
        $(".js-navbutton, .js-nav").toggleClass("active");
        $("body, html").toggleClass("freeze");
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * popup
	// *
	// *
    $(".js-popupform-button").click(function(){
        $(".js-popupform, .js-popupform-button").toggleClass("active");
        if(!$(".js-nav").hasClass("active")) {
            $("body, html").toggleClass("freeze");
        }
    });

    $(".js-popupform-button.error").trigger("click");




    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * slider
	// *
	// *
	var $slider = $(".js-slider");

	if ($slider.length){
		$slider.each(function(){
			$(this).slick({
				fade: false,
				dots: false,
				infinite: false,
				arrows: true,
				autoplay: true,
                autoplaySpeed: 5000,
				speed: 1000,
				prevArrow: $(this).parent().find(".js-slider-nav-prev"),
				nextArrow: $(this).parent().find(".js-slider-nav-next"),
			});
		});
	}


    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * slider
	// *
	// *
	var $teaserCarousel = $(".js-teasercarousel");

	if ($teaserCarousel.length){
		$teaserCarousel.each(function(){
			$(this).slick({
				fade: false,
				dots: false,
				infinite: true,
				arrows: true,
                variableWidth: true,
				autoplay: true,
				autoplaySpeed: 3500,
				speed: 1000,
				prevArrow: $(this).siblings(".js-slider-nav-prev"),
				nextArrow: $(this).siblings(".js-slider-nav-next"),
			});
		});
	}



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-button").click(function(){
        $(this).toggleClass("active").siblings().slideToggle();

        $('html, body').animate({
            scrollTop: ($(this).offset().top - 200)
        }, 400);
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * header language
    // *
    // *
    $(".js-header-language").click(function(){
        $(this).toggleClass("active");
    });

    $(window).on("scroll", function(){
        $(".js-header-language").removeClass("active");
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * video
    // *
    // *
    $(".js-video").each(function() {
        var link = $(this).find(".js-video-link").data("href");
        $(this).attr("href",link);
    })


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * jobSearch
    // *
    // *
    //toggle dropdown on input
    $(".js-job-search-city").on("input", function(){
        var city = $(this).val();

        // do ajax request
        $.ajax({
            url : "/assets/components/site/connector.php?action=getcities",
            type: 'GET',
            data: { city : city }
        }).done(function(data){
            if(data.status == 'success'){
                // do nothing
                console.log(data);

                // add cities to list
                $(".js-jobcitylist").html(data.data);

            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });

        $(".js-jobcitylist").addClass("active");

        // remove class without input value
        if(!$(this).val()) {
            $(".js-job-citylist").removeClass("active")
        }
    });

    // copy option name in input field
    $(".js-jobcitylist").on("click", ".js-jobcitylist-item", function() {
        var city = $(this).text();
        $(this).parent(".js-jobcitylist").removeClass("active").siblings(".js-job-search-city").val(city);
    })

    // slide up dropdown on mouseleave
    $(".js-job-search-wrapper").mouseleave(function() {
        $(this).find(".js-jobcitylist").removeClass("active");
    })

});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function(){
    var scroll = $(window).scrollTop();
    if (scroll > 100) {
        $(".js-header").addClass("collapse");
    }

    if (scroll <= 0) {
        $(".js-header").removeClass("collapse");
    }


});
